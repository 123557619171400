import React from 'react'
import PropTypes from 'prop-types'
import tastify from '@frontastic/catwalk/src/js/helper/tastify'
import Banner from '../../patterns/organisms/BannerElement'

const BannersTastic = ({
    data: {
        isDotted, bgColor, bannerList, reference, swiperColor,
    },
}) => (
    <Banner
        isDotted={isDotted}
        bgColor={bgColor}
        bannerList={bannerList}
        reference={reference}
        swiperColor={swiperColor}
    />
)

BannersTastic.propTypes = {
    data: PropTypes.object.isRequired,
}

export default tastify({ translate: true })(BannersTastic)

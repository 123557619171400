import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const BaseDotted = ({
    isTop,
    isBottom,
    className,
    dottedSVG,
    height = 1,
    from = 'left',
    isRotate = false,
    isInsetTop = false,
    isInsetBottom = false,
}) => {
    const style = {
        top: isInsetTop ? 0 : isTop && `-${height}px`,
        bottom: isInsetBottom ? 0 : isBottom && `-${height}px`,
        height: `${height}px`,
    }

    return (
        <div
            className={classnames('dotted-bar', {
                [`${className}`]: className,
                'dotted-bar__left': !from || from === 'left',
                'dotted-bar__right': from === 'right',
                'dotted-bar__top': isTop,
                'dotted-bar__bottom': isBottom,
                'dotted-bar__rotate': isRotate,
            })}
            style={style}
        >
            {dottedSVG}
        </div>
    )
}

BaseDotted.propTypes = {
    isTop: PropTypes.bool,
    isBottom: PropTypes.bool,
    isRotate: PropTypes.bool,
    isInsetTop: PropTypes.bool,
    isInsetBottom: PropTypes.bool,
    className: PropTypes.string,
    dottedSVG: PropTypes.element.isRequired,
    height: PropTypes.number.isRequired,
    from: PropTypes.oneOf(['left', 'right']),
}

export default BaseDotted

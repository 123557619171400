import React from 'react'
import PropTypes from 'prop-types'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import Markdown from '@frontastic/catwalk/src/js/component/markdown'
import FrontasticImage from '../../atoms/frontasticImage'

const Banner = ({
    title, desc, media, reference,
}) => (
    <div className={'banner-content hover-zoomin-image'}>

        <Reference
            reference={reference}
        >
            {media && (
                <FrontasticImage
                    media={media.media}
                    title={title}
                    height={650}
                    width={960}
                    style={{
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            )}
            <div className={'banner-text'}>
                {title && <h3>{title}</h3>}
                {desc && <Markdown text={desc} />}
            </div>
        </Reference>
    </div>
)

Banner.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    media: PropTypes.object.isRequired,
    reference: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default Banner

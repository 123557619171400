import React from 'react'
import { ReactComponent as SVGFadebarVariantA5mm } from '../../../../icons/sport2000-icons/fadebar/5mm-variant-A.svg'
import { ReactComponent as SVGFadebarVariantB5mm } from '../../../../icons/sport2000-icons/fadebar/5mm-variant-B.svg'
import { ReactComponent as SVGFullbarVariantA5mm } from '../../../../icons/sport2000-icons/fullbar/5mm-variant-A.svg'
import { ReactComponent as SVGFullbarVariantB5mm } from '../../../../icons/sport2000-icons/fullbar/5mm-variant-B.svg'

export const DottedFadebarVariantA5mm = (props) => {
    return <SVGFadebarVariantA5mm width='587px' height='14px' {...props} />
}

export const DottedFadebarVariantB5mm = (props) => {
    return <SVGFadebarVariantB5mm width='800px' height='14px' {...props} />
}

export const DottedFullbarVariantA5mm = (props) => {
    return <SVGFullbarVariantA5mm width='1163px' height='14px' {...props} />
}

export const DottedFullbarVariantB5mm = (props) => {
    return <SVGFullbarVariantB5mm width='1163px' height='14px' {...props} />
}

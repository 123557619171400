import React from 'react'
import PropTypes from 'prop-types'
import { SwiperSlide } from 'swiper/react'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import Slider from '../../atoms/slider'
import BannerBlock from './BannerBlock'
import BaseDotted from '../../atoms/dotted/BaseDotted'
import { DottedFadebarVariantA5mm, DottedFadebarVariantB5mm } from '../../atoms/dotted'
import FullPageWidthWrapper from '../../molecules/Layout/FullPageWidthWrapper'

const Banner = ({
    bannerList, isDotted, bgColor, swiperColor,
}) => {
    const isDesktop = useDeviceType() === 'desktop'

    const style = {
        backgroundColor: bgColor,
    }

    const renderBanners = () => bannerList.map((item, index) => {
        const {
            title, desc, reference, image,
        } = item

        if (isDesktop) {
            return (
                <SwiperSlide key={title + index}>
                    <BannerBlock
                        title={title}
                        desc={desc}
                        media={image}
                        reference={reference}
                    />
                </SwiperSlide>
            )
        }
        return (
            <BannerBlock
                key={title + index}
                title={title}
                desc={desc}
                media={image}
                reference={reference}
            />
        )
    })

    const renderBannersByDevice = () => {
        if (isDesktop && bannerList.length > 3) {
            return (
                <Slider
                    swiperColor={swiperColor}
                    spaceBetween={24}
                    slidesPerView={3}
                    slidesPerGroup={3}
                >
                    {renderBanners()}
                </Slider>
            )
        }

        return (
            <div className={'unactive-slide'}>
                {renderBanners()}
            </div>
        )
    }

    const renderDottedBar = () => {
        if (!isDotted) {
            return null
        }
        const dottedColor = bgColor
        return (
            <div className={'teaser--dotted-bar'}>
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantA5mm fill={dottedColor} />}
                    height={14}
                    isBottom
                    isRotate
                    from={'right'}
                />
                <BaseDotted
                    dottedSVG={<DottedFadebarVariantB5mm fill={dottedColor} />}
                    height={14}
                    isBottom
                    isRotate
                />
            </div>
        )
    }

    return (
        <FullPageWidthWrapper
            customClasses={classnames('relative', {
                'relative py-comp': bgColor,
                'my-comp': !bgColor,
                'mb-4': isDotted,
            })}
            style={style}
        >
            <div className={'o-wrapper'}>
                <div className={'o-grid o-region'}>
                    <div className={'o-cell o-cell--12'}>
                        <div className={'banner-text-element'}>
                            {renderBannersByDevice()}
                        </div>
                    </div>
                </div>
            </div>
            {renderDottedBar()}
        </FullPageWidthWrapper>
    )
}

Banner.propTypes = {
    bannerList: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        desc: PropTypes.string,
        media: PropTypes.object,
    })).isRequired,
    bgColor: PropTypes.string.isRequired,
    isDotted: PropTypes.bool.isRequired,
    swiperColor: PropTypes.string.isRequired,
}

export default Banner
